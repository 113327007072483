<template>
    <div>
        <div v-if="interface_ready">
            <div v-for="question in questionFilled" :key="question.question_id" class="row mb-3">
                <label class="col-sm-4 col-form-label" :for="question.question_code">{{ question.question_libelle }}</label>
                <component
                    :is="question.question_questiontype"
                    :code="question.question_code"
                    :values="question.question_questiondata"
                    :answer.sync="reponses[question.local_index]"
                    :placeholder="question.question_placeholder"
                    :q_id="question.question_id"
                    :answer_given="getGivenAnswer(question.question_id)"
                    :ws="question.webservice"
                    :allow_empty="false"
                />
            </div>
        </div>

        <LoadingSpinner class="col-12" v-else />

        <div v-if="showSemence" class="row mb-2">
            <div class="col-sm-4"><b>{{ $t("acte.nb_paillettes") }} : {{ semence_nb_paillettes }}</b></div>
            <div class="col-sm-8"><b>{{ $t("acte.volume_final") }} : {{ semence_volume_final }} </b></div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'

    import DateInput from '@/components/Questions/InputsType/DateInput'
    import NumberInput from '@/components/Questions/InputsType/NumberInput'
    import TextInput from '@/components/Questions/InputsType/TextInput'
    import LongtextInput from '@/components/Questions/InputsType/LongtextInput'
    import CheckboxInput from '@/components/Questions/InputsType/CheckboxInput'
    import MultiCheckboxInput from '@/components/Questions/InputsType/MultiCheckboxInput'
    import SelectInput from '@/components/Questions/InputsType/SelectInput'
    import MultiSelectInput from '@/components/Questions/InputsType/MultiSelectInput'

	import Embryon from '@/mixins/Embryon.js'
	import Gynecologie from '@/mixins/Gynecologie.js'
    import Navigation from '@/mixins/Navigation.js'
    import Question from '@/mixins/Question.js'
    import LocalWBS from '@/mixins/LocalWBS.js'
	import PlanningMonte from '@/mixins/PlanningMonte.js'

    import TableMixin from '@/mixins/Table.js'

    import LoadingSpinner from 'GroomyRoot/components/Logos/LoadingSpinner_35';

	export default {
		name: "QuestionForm",
        mixins: [Embryon, Gynecologie, Navigation, Question, LocalWBS, PlanningMonte, TableMixin],
        props: {
            question_type: { type: String, default: () => 'horse_actes_type' },
            question_fk: { type: [String, Number], default: () => null },
            reponse_type: { type: String, default: () => '' },
            reponse_fk: { type: [String, Number], default: () => null },
            params_wbs: { type: Object, default: () => null },
            preselect: { type: String, default: '' },
			is_ready: { type: Boolean, default: false },
            pre_reponses: { type: Array, default: () => []},
            horse: { type: Object, default: () => null }
        },
		data () {
			return {
                questions: [], // La liste des questions (c'est à ce composant de les charger, et les enregistrer sur demande)
                reponses: [], // La liste des réponses (c'est à ce composant de les charger, et les enregistrer sur demande)

                input_answers: [], // Buffer de réception pour les réponses de chaque input écrites par l'utilisateur
                type_code_webservice: ['WBS', 'WBC'],// Pour définir dynamiquement le code correspondant à un webservice
                question_data_type_local: 'local',
                interface_ready: false, // Indique que le système est correctement initialisé et prêt à gérer les ajouts ou validation d'actes
                old_sms_id: null,
                semence_nb_paillettes: -1,
                semence_volume_final: -1
			}
		},
        created() {
            this.loadQuestions()
        },
		methods: {
            /* Charge les questions (et implicitement leurs réponses si elles existent), pour un id d'acte type. Ramise à zéro des valeurs. */
            async loadQuestions() {
                if(!this.question_fk) {
                    this.interface_ready = true
                    if(this.pre_reponses.length > 0) {
                        await this.loadReponses()
                    }
                    return false
                }
                this.interface_ready = false

                try {
                    // Chargement des questions
					this.questions = await this.getQuestions(this.question_type, this.question_fk)

                    // Conversion automatique des webservices éventuels en questions
                    await this.loadWebServiceQuestions()

                    // Chargement des réponses et application pour celles déjà données
                    await this.loadReponses()
                }
                catch(error) {
                    console.error("WriteActes::loadQuestions", error)
                    return false
                }
                this.interface_ready = true
                return true
            },

            /* Conversion des webservices éventuels en questions */
            async loadWebServiceQuestions() { 
                // Pour chaque question de type webservice, de manière asynchrone
                const questions_webservices = this.questions.filter(question => this.type_code_webservice.includes(question.question_questiontype))
                let questions_a_ajouter = {} // Contient un ensemble d'arrays [id de la question à remplacer : [contenu]]

                // On traite la récupération de tous les webservices de manière asynchrone en parallèle
                const promises = []

                questions_webservices.forEach(async question_webservice => {
                    promises.push(new Promise(async (resolve, reject) => {
                        let question_data = question_webservice.question_questiondata

                        if(question_data.type == this.question_data_type_local) {
                            const wb_name = question_data.data[0]
                            question_webservice.webservice = true
                            if(question_webservice.question_questiontype == "WBS") {
                                question_webservice.question_questiontype = "SEL"
                            } else if(question_webservice.question_questiontype == "WBC") {
                                question_webservice.question_questiontype = "MSL"
                            }
                            question_webservice.question_questiondata['type'] = "data"
                            questions_a_ajouter[question_webservice.question_id] = await this.resolveWebservicesInterfaces(wb_name, question_webservice, this.params_wbs, this.preselect)
                        }
                        else {
                            const result = await this.getQuestionWebService(question_data.data[0])
                                .catch(e => {
                                    console.error("QuestionForm::loadWebServiceQuestions", e)
                                    reject()
                                })
                            if(result && result.retour) {
                                // Pour chaque question contenue dans le webservice
                                questions_a_ajouter[question_webservice.question_id] = []

                                for(const [insertion_index, question_retour] of Object.entries(result.retour)) {
                                    questions_a_ajouter[question_webservice.question_id].push(question_retour)
                                }
                            }
                        }
                        resolve()
                    }))
                })

                // On attend que tout soit correctement traité
                await Promise.all(promises)

                // Enfin, on insère les questions aux bons endroits
                for (var key in questions_a_ajouter){
                    const index = this.questions.findIndex(question => {
                        if(question.question_id == key) return true
                    })
                    let insertion_index = 0
                    questions_a_ajouter[key].forEach(question_a_ajouter => {
                        this.questions.splice(index+insertion_index, (insertion_index == 0 ? 1 : 0), question_a_ajouter)
                        insertion_index++
                    })
                }
            },

            /* Charge et applique les réponses aux questions id pour l'acte id en cours */
            async loadReponses() {
                try {
                    let reponses = []
                    // Récupération des réponses depuis la base de données (uniquement si on n'est pas en mode création)
                    let reps = []
                    if(this.reponse_fk) {
                        reps = this.isEditing ? await this.getAnswers(this.reponse_type, this.reponse_fk) : []
                    }
                    else if(!this.reponse_fk && this.pre_reponses) {
                        reps = this.pre_reponses
                    }

                    // Pour chaque question
                    this.questions.forEach(question => {
                        // Vérifier si la réponse existe
                        let val = question.question_default_value
                        reps.forEach(rep => {

                            if(rep.reponse_question == question.question_id) {

								// Chercher la bonne réponse dans les infos de la question grace au seasonmarestallion_id
								if(rep.reponse_data_hidden && rep.reponse_data_hidden.type === 'season_mare_stallion') {

									// Pour chaque groupe (saison/porteuse/autre)
									question.question_questiondata.data.forEach(group => {

										// Pour chaque couple
										group.values.forEach(option => {
											if(option.uniqueIdentifier === rep.reponse_data_hidden.id) {
												val = option
											}
										})
									})
								}
								// Chercher le bon embryon dans les propositions
								else if(rep.reponse_data_hidden && rep.reponse_data_hidden.type === 'embryon') {
									const embryon_ids = rep.reponse_data_hidden.ids
									val = []

									Object.keys(question.question_embryons).forEach(label => {
										const embryon_id = question.question_embryons[label]

										if(embryon_ids.includes(embryon_id)){
											val.push(label)
										}
									})
								}
								else {
									val = rep.reponse_data
								}
                            }
                        })
                        // Ajouter la valeur éventuellement trouvée dans le tableau des réponses
                        const x = {'key': question.question_id, 'val': val}
                        reponses.push(x)
                    })
                    this.reponses = reponses
                }
                catch(error) {
                    console.error("QuestionForm::loadReponses", error)
                    return false
                }
            },

            /* Pour générer les composants de questions, permet d'affecter les réponses */
            getGivenAnswer(q_id) {
                const reponse = this.reponses.filter(reponse => reponse.key == q_id)
                return reponse.length > 0 && reponse[0].val ? reponse[0] : null
            },

            /* Prépare les réponses pour un format acceptable par l'API pour la sauvegarde */
            async prepareReponses() {
                const reponses = this.reponses
                const questions = this.questionFilled.map(question => question.question_id)
                let total = []

                for (let i=0; i<reponses.length; i++) {
                    if(questions.includes(reponses[i].key)) {
                        let tmp = {}
                        tmp['reponse_question'] = reponses[i].key
                        
                        if(reponses[i].val && reponses[i].val.id) {
                            tmp['reponse_data'] = Array.isArray(reponses[i].val) ? reponses[i].val.id : [reponses[i].val.id]
                        }
                        else {
                            tmp['reponse_data'] = Array.isArray(reponses[i].val) ? reponses[i].val : [reponses[i].val]
                        }
                        
                        // si webservice stallion je rajoute l'id du cheval sélectionné dans reponse_data_hidden
                        if(this.questions[i].question_stallion) {
    						let compare_horse = reponses[i].val
							if(compare_horse.charAt(0) === ' ') {
								compare_horse = compare_horse.trim()
							}
                            const horse = this.questions[i].question_stallion.filter(stallion => stallion.horse_nom === compare_horse)
                            if(horse) {
                                tmp['reponse_data_hidden'] = {type: "stallion", id: horse[0].horse_id}
                            }
                        }
                        // si webservice laboratoire je rajoute l'id du labo
                        else if(this.questions[i].question_laboratoire) {
                            const labo = this.questions[i].question_laboratoire[reponses[i].val.id]
                            if(labo) {
                                tmp['reponse_data_hidden'] = {type: "laboratoire", id: labo.id}
                            }
                        }
                        // si webservice matrice je rajoute le code des matrices
                        else if(this.questions[i].question_matrice) {
                            if(reponses[i].val && reponses[i].val.length > 0 && reponses[i].val[0] !== null && reponses[i].val[0] !== undefined) {
                                const codes = tmp['reponse_data'].map(m => this.questions[i].question_matrice[m].code);
                                tmp['reponse_data_hidden'] = {type: "matrice", code: codes}
                            }
                        }
                        // si webservice resultat je rajoute le code de la resultat
                        else if(this.questions[i].question_resultat) {
                            const resultat = this.questions[i].question_resultat[tmp['reponse_data'][0]]
                            tmp['reponse_data_hidden'] = {type: "resultat", code: resultat.code}
                        }
                        // si webservice interpretation je rajoute le code de la interpretation
                        else if(this.questions[i].question_interpretation) {
                            const interpretation = this.questions[i].question_interpretation[tmp['reponse_data'][0]]
                            tmp['reponse_data_hidden'] = {type: "interpretation", code: interpretation.code}
                        }
						// Lors du choix du couple de la jument
						else if(this.questions[i].question_season_mare_stallions && reponses[i].val) {
							const option_id = reponses[i].val.uniqueIdentifier

							// Récupération du seasonmarestallion_id à partir des options
							let seasonmarestallion_id = this.questions[i].question_season_mare_stallions[option_id]

							// Lorsqu'un "Autres étalons" a été choisi
							if(!seasonmarestallion_id && this.questions[i].question_other_stallions) {
								// Comme les actes avec cette question ne peuvent pas etre modifiés en lot,
								// on peut se permettre de ne prendre que le premier horse_id
								const mare_id = this.params_wbs.horses_ids[0]
								const stallion_id = this.questions[i].question_other_stallions[option_id]

								if(mare_id && stallion_id) {
									// Créer le season, season_mare et le season_mare_stallion si ils n'existent pas
									seasonmarestallion_id = await this.getOrCreateSeasonMareStallionHorse(mare_id, stallion_id, this.params_wbs.actes_date)
								}
							}

							if(seasonmarestallion_id) {
								// Se souvenir seulement du nom de l'étalon car on retrouve l'option grace au reponse_data_hidden
								tmp['reponse_data'] = [tmp['reponse_data'][0]['value'].split('/')[0]]
								tmp['reponse_data_hidden'] = {type: "season_mare_stallion", id: seasonmarestallion_id}
							}
						}
                        // choix d'un pays
                        else if(this.questions[i].question_pays && reponses[i].val) {
                            const pays = Object.entries(this.questions[i].question_pays).find(pays => pays[1] == reponses[i].val.id)
                            tmp['reponse_data_hidden'] = {type: "pays", id: pays[0]}
                        }
                        // choix d'un lieu
                        else if(this.questions[i].question_lieu && reponses[i].val) {
                            const lieu = this.questions[i].question_lieu.find(lieu => lieu.lieu_label == reponses[i].val.id)
                            if(lieu) {
                                tmp['reponse_data_hidden'] = {type: "lieu", id: lieu.lieu_id}
                            }
                        }
                        // choix des embryons
                        else if(this.questions[i].question_embryons) {
							let embryon_ids = (reponses[i].val || []).map(embryon_label => {
								return this.questions[i].question_embryons[embryon_label]
							})
							tmp['reponse_data_hidden'] = {type: "embryon", ids: embryon_ids}
                        }
                        total.push(tmp)
                    }
                }

                return total
            },

            /* Vérifie la validité des réponses aux questions (renvoie true ou false) */
            checkQuestions() {
                for (let i=0; i<this.questions.length; i++) {
                    if(this.questions[i].question_code == "Temperature"){
                        if (this.reponses && this.reponses[i] && this.reponses[i].val && this.reponses[i].val!= 0){
                            return false
                        } else {
                            return this.getTrad('temperature.egale_zero')
                        }
                    }
                }
                return false
            },

            /** Vérifie si la question doit être affichée en fonction de ses conditions
             * on remplace le pattern #_QUESTION_# par les réponses de la question
             * puis on eval le résultats
             */
            testQuestion(question) {
                const condition = question.question_condition
                let validate = false

                if(!condition || condition == "" || condition == " ") {
                    validate = true
                }
                else{
                    const questions = this.questions
                    const reponses = this.reponses

                    let reponse = ""

                    const parse = condition.replace(/(#_[A-zÀ-ú0-9]*_#)/g, function(match) {
                        const code = match.slice(2,-2)
                        const q_id = questions.find(q => q.question_code == code).question_id

                        reponse = reponses.find(reponse => reponse.key == q_id)
                        if(reponse.val && reponse.val.id) {
                            reponse = reponse.val.id
                        }
                        else {
                            reponse = reponse.val
                        }

                        if(reponse) {
                            if(reponse && typeof reponse != "string" && typeof reponse != "boolean"){
                                reponse = reponse.join(' ')
                            }
                        } else {
                            reponse = ""
                        }

                        return "`${reponse}`"
                    })

                    validate = eval(parse);
                }

                if(!question.question_questiondata.data){
                    return validate
                }

                const idx = this.reponses.findIndex(reponse => reponse.key == question.question_id)

                if(!validate) {
                    // si je n'affiche pas la question alors je reset la réponse liée
                    this.reponses[idx].val = null
                }
                else if(question.question_questiondata.data.length == 1 && !this.reponses[idx].val) {
                    // Si la question n'a qu'une réponse, je la sélectionne
                    if(question.question_questiontype == "SEL") {
						let val = question.question_questiondata.data[0]

						// Pour les select groupés on ne préselectionne rien
						if(val.groupLabel) {
							val = null
						}

						this.reponses[idx].val = val
                    }
                    if(question.question_questiontype == "MSL") {
                        this.reponses[idx].val = [question.question_questiondata.data[0]]
                    }
                }
                else if(question.question_questiondata.data.includes('Negatif') && !this.reponses[idx].val) {
                    // Si la question a plusieurs réponse dont 'Negatif' alors je selectionne 'Negatif'
                    this.reponses[idx].val = 'Negatif'
                }
                else if(question.question_questiondata.data.includes('(14) LABEO') && !this.reponses[idx].val && question.question_code == "Laboratoire") {
                    // Si la question est "Laboratoire" et que j'ai alors je le sélectionne
                    this.reponses[idx].val = '(14) LABEO'
                }
                else if(question.question_resultat && this.preselect == "NEG" && !this.reponses[idx].val) {
                    // Si la question a plusieurs réponse dont 'Negatif' alors je selectionne 'Negatif'
                    this.reponses[idx].val = 'Negatif'
                }

                return validate
            },

            updateQuestion(reponse) {
                this.updateQuestionAnalyse(reponse)
                this.updateQuestionResult(reponse)
            },

            /** 
             * met à jour la question Analyse des actes types sanitaire
             * en fonction de la réponse à la question Laboratoire
             */
			updateQuestionAnalyse(reponse) {
				const type_analyse = [
					"TEST_NEW2_ANMIE_INFCTIEUSE_DES_EQUIDS_AIE",
					"TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_TALON",
					"TEST_NEW2_MTRITE_CONTAGIEUSE_QUINE_MEC_POUR_JUMENT",
                    "TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_JUMENT",
                    "TEST_NEW2_ARTRITE_VIRALE_QUINE_EVA_POUR_TALON"
				];

				if(this.params_wbs && !type_analyse.includes(this.params_wbs.acte_type_code)) return false
				const question = this.questions.find(q => q.question_id == reponse.key)

				if(question.question_code !== "Laboratoire")  return false

				const question_analyse = this.questions.find(q => q.question_code.includes("Analyses demandées"));
				if(!question_analyse) return false

				const analyse_idx = this.questions.findIndex(q => q.question_id == question_analyse.question_id)

				if(!question_analyse.question_questiondata.hidden) {
					this.questions[analyse_idx].question_questiondata.hidden = question_analyse.question_questiondata.data
				}
				this.questions[analyse_idx].question_questiondata.data = []

                let analyse = null
                if(reponse && reponse.val && reponse.val.label) {
                    analyse = question.question_laboratoire[reponse.val.label].analyse
                }
                else {
                    analyse = question.question_laboratoire[reponse.val].analyse
                }

				this.questions[analyse_idx].question_questiondata.data = this.questions[analyse_idx].question_questiondata.hidden.filter(rep => {
					if(rep.includes('Culture') && analyse.includes('CULT_AG')) return true
					if(rep.includes('PCR') && analyse.includes('PCR_AG')) return true
					if(rep.includes('Immuno') && analyse.includes('IMMIND')) return true
					if(rep.includes('Séroneutralisation') && analyse.includes('SRNTRLST')) return true
					if(rep.includes('ELISA') && analyse.includes('ELISA_VAL')) return true
					if(rep == "Autres germes" || rep == "Antibiogramme" || rep == "Coggins" || rep == "-") return true
				})
			},

            /** 
             * - RESULTAT ANALYSE -
             * si le résultat est négatif alors on set l'interprétation à négatif
             */
            updateQuestionResult(reponse) {
                if(this.question_type !== "horse_actes_result_type" || reponse.val !== "Negatif") return false

                const question = this.questions.find(q => q.question_id == reponse.key)
                if(question.question_code !== "Résultat")  return false

                const question_interpre = this.questions.find(q => q.question_code.includes("Interprétation"));
                if(!question_interpre) return false

                const idx = this.reponses.findIndex(reponse => reponse.key == question_interpre.question_id)
                this.reponses[idx].val = "Negatif"
            },

            setReponse(reponse, question_code) {
                const question_analyse = this.questions.find(q => q.question_code == question_code);
                if(!question_analyse) return false

                const idx = this.reponses.findIndex(rep => rep.key == question_analyse.question_id)
                this.reponses[idx].val = {id: reponse, label: reponse}
            },

            async checkReponses(reponses) {
                await Common.asyncForEach(reponses, async (reponse) => {
                    if(reponse.val) {
                        if(reponse.val.uniqueIdentifier) {
                            await Common.asyncForEach(this.questions, async (question) => {
                                if(question.question_season_mare_stallions && question.question_season_mare_stallions[reponse.val.uniqueIdentifier]
                                && question.question_season_mare_stallions[reponse.val.uniqueIdentifier] != this.old_sms_id) {
                                    this.old_sms_id = question.question_season_mare_stallions[reponse.val.uniqueIdentifier]
                                    await this.getSeasonMareStallionById(question.question_season_mare_stallions[reponse.val.uniqueIdentifier])
                                    .then((sms) => {
                                        if(sms && (sms.seasonmarestallion_status == 'Non saillie' || sms.seasonmarestallion_status.includes('Transplanté'))) {
                                            this.infoToast("toast.warning_dg")
                                        }
                                    })
                                }
                            })
                        }

                        this.updateQuestion(reponse)
                    }
                })
            },

            updateReponse(question_code, value) {
                const question = this.questions.find(q => q.question_code == question_code);
                if(!question) return false

                const idx = this.reponses.findIndex(rep => rep.key == question.question_id)
                this.reponses[idx].val = value
                
                this.interface_ready = false
                this.$nextTick(() => {
                    this.interface_ready = true
                })
            },

            updateSemenceValue(reponses) {
                this.semence_nb_paillettes = -1
                this.semence_volume_final = -1

                const quest_concentration = this.questions.find(quest => quest.question_code == "CONCENTR")
                const quest_volume = this.questions.find(quest => quest.question_code == "VOL")
                const quest_sperm = this.questions.find(quest => quest.question_code == "SPERM")

                if(!quest_concentration || !quest_volume || !quest_sperm) {
                    return false
                }

                const rep_concentration = this.reponses.find(rep => rep.key == quest_concentration.question_id)
                const rep_volume = this.reponses.find(rep => rep.key == quest_volume.question_id)
                const rep_sperm  = this.reponses.find(rep => rep.key == quest_sperm .question_id)

                if(!rep_concentration || !rep_volume || !rep_sperm || (rep_concentration && !rep_concentration.val) || (rep_volume && !rep_volume.val) || (rep_sperm && !rep_sperm.val)) {
                    return false
                }

                this.semence_nb_paillettes = parseInt(rep_concentration.val * rep_volume.val * rep_sperm.val * 8 / 400)
                this.semence_volume_final = parseInt(rep_concentration.val * rep_volume.val * rep_sperm.val * 8 / 400 * 0.56)
            }
        },
        computed: {
            // Retourne true si on est sur une édition / validation d'un acte
            isEditing() {
                return !!this.reponse_fk
            },
            // Retourne l'ensemble des questions pour cet acte qui ne sont pas un webservice
            questionFilled() {
				const questions = this.questions.filter(question => !this.type_code_webservice.includes(question.question_questiontype) && this.testQuestion(question))
				return questions.map(question => {
					question.local_index = this.questions.findIndex(q => (q.question_id === question.question_id))
					return question
				})
            },

            showSemence() {
                return this.params_wbs && this.params_wbs.acte_type_code == 'REPRO_COLLECTE_SEMENCE' && this.semence_nb_paillettes !== -1 && this.semence_volume_final !== -1
            }
        },
        watch: {
            // Si on a un type sélectionné, on charge les questions associées à ce type d'acte (remise à zéro des réponses)
            'question_fk' (val) {
                if(this.interface_ready) this.loadQuestions()
            },
            // On récupére la réponse donnée à une question (key) à partir du buffer de QuestionForm
            async 'reponses' (reponses) {
                await this.checkReponses(reponses)
				this.$emit('update:update_question', this.reponses);
                if(this.params_wbs && this.params_wbs.acte_type_code == 'REPRO_COLLECTE_SEMENCE') {
                    this.updateSemenceValue(reponses)
                }
			},
			'params_wbs.actes_date': {
				handler(val) {
					if(val) {
						this.loadQuestions()
					}
				},
				deep: true
			},
			interface_ready(val) {
				this.$emit('update:is_ready', val)
			}
        },
        components: {
            LoadingSpinner,
            'SEL': SelectInput, // Menu déroulant
            'MCQ': MultiSelectInput, // Menu déroulant à choix multiples
            'DAT': DateInput, //Date
            'TXT': TextInput, //Champs de texte court
            'LTX': LongtextInput, //Champs de texte long
            'NUM': NumberInput, //Nombre
            'BOO': CheckboxInput, // Case à cocher
            'MSL': MultiCheckboxInput, // Cases à cocher
        }
    }

</script>
